import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const HomeBannerImage = (props) => {
    return (
        <video
            src="https://ggfx-cnestateagents.s3.eu-west-2.amazonaws.com/i.prod/cartergroves_1185083d65.mp4"
            alt="banner-img"
            className="banner-img"
            ref={props.videoRef}
            autoPlay="autoplay" playsInLine="playsinline" loop="true" muted="true"
        />
    )

}

export default HomeBannerImage